import { Box } from "@mui/material";
import { CustomAvatar } from "./Avatar/Avatar";
import Text from "./Text/Text";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getCurrentTimeInSecond,
  getDefaultImage,
  getLightColor,
  getValidURL,
  isLightColor,
  validateColor,
} from "./constants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Timer from "./Timer";
import ExpireModal from "./ExpireModal";
import { CustomPopper } from "./Popper/Popper";
import { close, timerTooltip } from "../messages";
import ClockTimer from "./ClockTimer/ClockTimer";
import HowToPayDrawer from "./HowToPayDrawer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TruncatedTextTooltip from "./TruncatedTextTooltip";
import Translation from "./Translation";

const AccountNameBox = ({
  textStyle,
  accountName,
  brandingPageUI,
  currentAccount,
  paymentInfo,
  previewColor,
  confirmPayment,
  fontStyle,
  type,
  stickyHeader = false,
  totalAmount,
  setOpenModal,
  headerModal = false,
  liveMode,
  visibleInPaymentPage,
  expireTimeHandler,
}) => {
  const maxStartingTime = 600;
  const expiresAt = paymentInfo?.payment?.expires_at;
  const [showHowToPayDrawer, setShowHowToPayDrawer] = useState(false);
  const [textColor, setTextColor] = useState("#fff");
  const [defaultImage, setDefaultImage] = useState(
    getDefaultImage(textColor, currentAccount)
  );
  const [accountImage, setAccountImage] = useState(defaultImage);
  const [showTimerInFooter, setShowTimerInFooter] = useState(false);
  const [timerTime, setTimerTime] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancelUrlModal, setOpenCancelUrlModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getImageDimension = () => {
    // We are keeping the same height-width for the icon and logo in all cases, so we've set both the logo and icon in the original format.
    const icon = brandingPageUI.icons?.original;
    const logo = brandingPageUI.logos?.original;
    return { icon, logo };
  };

  useEffect(() => {
    setShowTimerInFooter(
      getCurrentTimeInSecond(paymentInfo?.payment?.expires_at) < maxStartingTime
    );
  }, [expiresAt]);

  useEffect(() => {
    const defaultImageUpdate = getDefaultImage(textColor, currentAccount);
    setDefaultImage(defaultImageUpdate);
  }, [textColor]);

  useEffect(() => {
    if (brandingPageUI) {
      const { primary_color, primarySavedColor, use_logo_or_icon } =
        brandingPageUI;
      if (use_logo_or_icon) {
        const { icon, logo } = getImageDimension();
        if (use_logo_or_icon === "logo") {
          setAccountImage(logo || defaultImage);
        } else if (use_logo_or_icon === "icon") {
          setAccountImage(icon || defaultImage);
        }
      } else {
        setAccountImage(defaultImage);
      }
      const selectedColor = validateColor(primary_color)
        ? primary_color
        : primarySavedColor;
      isLightColor(selectedColor)
        ? setTextColor("#0a193e")
        : setTextColor("#fff");
    }
  }, [brandingPageUI]);

  const imageClassName =
    brandingPageUI && brandingPageUI.use_logo_or_icon === "logo"
      ? `account-logo-style checkout`
      : `account-icon-style`;

  const ShowDefaultLogo = () => {
    return (
      <Box
        className="account-logo-default"
        sx={{
          backgroundColor: `${getLightColor(previewColor, 0.4)} !important`,
        }}
      >
        {defaultImage}
      </Box>
    );
  };

  const ShowDynamicLogo = () => {
    return (brandingPageUI.use_logo_or_icon === "icon" &&
      !brandingPageUI.icons) ||
      (brandingPageUI.use_logo_or_icon === "logo" && !brandingPageUI.logos) ? (
      <ShowDefaultLogo />
    ) : (
      <CustomAvatar
        size="small"
        src={accountImage}
        className={imageClassName}
      />
    );
  };

  const AccountNameText = () => {
    const maxWidth = currentAccount?.cancel_url ? "180px" : "200px";
    return (
      <TruncatedTextTooltip
        textValue={accountName}
        textProps={{ ...textStyle }}
        cellWidth={liveMode ? "240px" : maxWidth}
        className="custom-tooltip account-name-text"
      />
    );
  };

  const iconStyle = {
    height: "30px",
    width: "30px",
    color: textStyle.color,
    marginRight: "10px",
    cursor: "pointer",
  };

  const handleTryAgain = () => {
    expireTimeHandler();
    setTimerTime(false);
  };

  const isFromCheckoutPaymentPage =
    visibleInPaymentPage && paymentInfo?.payment && !confirmPayment;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {!confirmPayment && currentAccount?.cancel_url ? (
          <ArrowBackIcon
            sx={{ ...iconStyle }}
            onClick={() => setOpenCancelUrlModal(true)}
          />
        ) : null}
        {brandingPageUI?.logos || brandingPageUI?.icons ? (
          <ShowDynamicLogo />
        ) : (
          <ShowDefaultLogo />
        )}

        {stickyHeader || headerModal ? (
          <Text
            size={16}
            className="grey-text"
            sx={{
              color: stickyHeader && textStyle.color,
              fontFamily: textStyle.fontFamily,
              cursor:
                paymentInfo?.payment?.invoice_line_items?.length > 0 &&
                "pointer",
            }}
            withIcon="end"
            onClick={() => {
              if (paymentInfo?.payment?.invoice_line_items?.length > 0) {
                setOpenModal(true);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          >
            {totalAmount}
            {paymentInfo?.payment?.invoice_line_items?.length > 0 && (
              <KeyboardArrowDownIcon
                sx={{
                  color: textStyle.color,
                  width: "20px",
                  height: "20px",
                  ml: "0 !important",
                  mt: "2px",
                }}
              />
            )}
          </Text>
        ) : (
          <>
            {brandingPageUI?.use_logo_or_icon !== "logo" && <AccountNameText />}
            {!liveMode && type === "web" && (
              <Box className="content-center test-mode-badge">
                <Text
                  size={14}
                  className="default-text"
                  font="regular"
                  variant="subtitle"
                  sx={{ fontFamily: textStyle.fontFamily }}
                >
                  <Translation value="testMode" />
                </Text>
              </Box>
            )}
          </>
        )}
      </Box>

      <CustomPopper
        disablePortal={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <Text
          font="regular"
          size={14}
          className="default-text"
          variant="subtitle1"
        >
          {timerTooltip}
        </Text>
      </CustomPopper>

      {headerModal && (
        <Box>
          <Text
            size={16}
            sx={{
              fontFamily: textStyle?.fontFamily,
              cursor: "pointer",
            }}
            withIcon="end"
            onClick={() => setOpenModal(false)}
            className="grey-text"
          >
            {close}
            <KeyboardArrowUpIcon
              sx={{
                width: "20px",
                height: "20px",
                ml: "0 !important",
                mt: "2px",
              }}
            />
          </Text>
        </Box>
      )}
      {paymentInfo?.payment?.expires_at &&
        isFromCheckoutPaymentPage &&
        !headerModal && (
          <Box display="flex" alignItems="center" component="div">
            {showTimerInFooter && (
              <ClockTimer
                previewColor={previewColor}
                paymentInfo={paymentInfo}
                timerColor={fontStyle.color}
              />
            )}
            <Timer
              onClick={handleClick}
              maxStartingTime={maxStartingTime}
              fontStyle={textStyle}
              paymentInfo={paymentInfo}
              getTimerTime={(value) => {
                setTimerTime(value);
              }}
              showTimerSection={showTimerInFooter}
              setShowTimerSection={(showTimer) =>
                setShowTimerInFooter(showTimer)
              }
            />
            <HelpOutlineIcon
              sx={{
                height: "18px",
                width: "18px",
                color: textStyle.color,
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() => setShowHowToPayDrawer(true)}
            />
          </Box>
        )}

      {isFromCheckoutPaymentPage && (
        <HowToPayDrawer
          fontFamily={textStyle.fontFamily}
          openHowToPayDrawer={showHowToPayDrawer}
          closeHowToPayDrawer={() => setShowHowToPayDrawer(false)}
        />
      )}
      {timerTime && (
        <ExpireModal
          open={timerTime}
          handleClose={() => handleTryAgain()}
          cancel_url={currentAccount?.cancel_url}
          handleCancel={() =>
            (window.location.href = getValidURL(currentAccount?.cancel_url))
          }
        />
      )}
      {currentAccount?.cancel_url && openCancelUrlModal && (
        <ExpireModal
          open={openCancelUrlModal}
          cancel_url={currentAccount?.cancel_url}
          handleClose={() => {
            setOpenCancelUrlModal(false);
            window.location.href = getValidURL(currentAccount.cancel_url);
          }}
          handleCancel={() => setOpenCancelUrlModal(false)}
          isFromCancel={true}
        />
      )}
    </Box>
  );
};

export default AccountNameBox;
