import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { handleUpdateCheckoutSession, showAmount } from "./constants";
import Text from "./Text/Text";
import CustomSelect from "./Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { PaymentPageContext } from "../contexts/contexts";
import CommonSkeleton from "./CommonSkeleton";

const AmountOptionsPreview = ({
  paymentInfo,
  fontStyle,
  checkoutSessionId,
  visibleInPaymentPage,
  paymentStatus,
  selectedAssetCurrency,
  selectedPaymentMethod,
  paymentDetailsObj,
  assetAmountLoader,
}) => {
  const [amountOptions, setAmountOptions] = useState(null);
  const [currencyValue, setCurrencyValue] = useState("");
  const [amountOptionValue, setAmountOptionValue] = useState({
    label: "",
    value: "",
  });
  const [apiCalled, setApiCalled] = useState(false);

  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);

  const setDefaultValue = () => {
    if (paymentInfo?.amountOptions) {
      let activeIndex = 0;
      const result = paymentInfo.amountOptions.map((item, index) => {
        const optionAmount = item?.option || item;
        const totalAmount = `${optionAmount} ${paymentInfo.currency.code}`;
        if (optionAmount === paymentInfo?.amount) {
          activeIndex = index;
        }

        return {
          label: totalAmount,
          value: totalAmount,
          amount: optionAmount,
        };
      });

      if (result.length) {
        setAmountOptionValue(result[activeIndex]);
        setCurrencyValue(result[activeIndex]?.amount);
        setAmountOptions(result);
      }
    }
  };

  useEffect(() => {
    !visibleInPaymentPage && setDefaultValue();
  }, [paymentInfo]);

  useEffect(() => {
    !apiCalled && setDefaultValue();
  }, [apiCalled]);

  useEffect(() => {
    setDefaultValue();
  }, [selectedAssetCurrency, paymentDetailsObj]);

  const currencyRender = (value) => {
    const exchangeRate =
      paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate;
    const shouldDisplay = selectedAssetCurrency !== paymentInfo.currency.code;

    if (exchangeRate && shouldDisplay) {
      let amount = +showAmount({
        amount: exchangeRate * value,
        currency: selectedAssetCurrency,
        multiplier: 1,
      });
      amount = selectedAssetCurrency === "SATS" ? Math.ceil(amount) : amount;

      return (
        <Text
          className="checkout-acc-currency"
          size={18}
          font="regular"
          variant="subtitle1"
          sx={fontStyle}
        >
          (≈ {amount} {selectedAssetCurrency})
        </Text>
      );
    }

    return "";
  };

  const updateCheckoutSession = (amount) => {
    if (visibleInPaymentPage) {
      const { setIsToastOpen, setToastMessage } = paymentPageData;
      handleUpdateCheckoutSession({
        amount,
        currency: paymentInfo.currency.code,
        paymentPageData,
        checkoutSessionId,
        target_currency: selectedAssetCurrency,
        payment_methods: [selectedPaymentMethod],
      })
        .then(() => {
          setApiCalled(true);
        })
        .catch((errorMessage) => {
          if (errorMessage) {
            setToastMessage(errorMessage);
            setIsToastOpen(true);
          }
          setDefaultValue();
          setApiCalled(false);
        });
    }
  };

  const handleCustomSelect = (e) => {
    if (paymentInfo?.payment?.exchange_rate) {
      setAmountOptionValue({
        label: e.target.value,
        value: e.target.value,
      });
      const result = amountOptions.find((item) => {
        return item.value === e.target.value;
      });
      setCurrencyValue(result?.amount);
      updateCheckoutSession(result?.amount);
    }
  };

  return (
    paymentInfo?.amountOptions?.length > 0 &&
    amountOptions && (
      <Box display="flex" flexDirection="column">
        {assetAmountLoader ? (
          <CommonSkeleton animation="square" width={250} height={50} />
        ) : (
          <>
            <CustomSelect
              disabled={paymentStatus === "paid"}
              name="amount_options"
              displayEmpty
              className="amount-options-preview"
              value={amountOptionValue?.value}
              MenuProps={{
                id: "amount-options",
              }}
              onChange={handleCustomSelect}
              renderValue={(value) => (
                <Text component="span" size={36} noWrap>
                  {value}
                </Text>
              )}
            >
              {amountOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelect>
            {currencyRender(currencyValue)}
          </>
        )}
      </Box>
    )
  );
};

export default AmountOptionsPreview;
